import React from 'react';
import contact from '../../assets/pexels-mart-production-7709252-scaled.jpg'
import Topbar from '../../Components/Topbar/Topbar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './ContactUs.css';
import logoImg from '../../assets/his.png';
import contact_card from '../../assets/13722099_5328478.jpg';
import ContactForm from '../../Components/ContactForm/ContactForm';
import HeaderFoot from '../../Components/HeaderFoot/HeaderFoot';

const ContactUs = () => {
  return (
    <div>
         <div className="bg-image darken-with-text" style={{
            backgroundImage: `url(${contact})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '780px',
            position: 'relative',
          }}>
            <Topbar/>
            <div className="contact-container">
              <h1 className='contact'>Contact Us</h1>
            </div>
        </div>

        <Container>
            <Row>
                <Col>
                   <h1 className='contact-h1'>NITHILA INN'S URBAN ESCAPE</h1>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={6}>
                    <img className='his-img' src={logoImg} alt='logo' style={{ width: "100%", height: "auto", objectFit: "cover" }} />
                </Col>
                <Col xs={12} lg={6}>
                    <p className='contact-content'>Nithila Inn, a serene retreat just moments from the Colombo – Matara Express Way, invites you to experience the perfect 
                        blend of urban convenience and suburban calm. Discover our restaurant, bar, reception hall, and clean, 
                        spacious rooms—all offering breathtaking views of nature’s beauty. 
                        <br />Welcome to Nithila Inn, where every stay is a peaceful escape and every meal a moment to savor.</p>
                </Col>
            </Row>
        </Container>

        <Container>
            <Row>
                <Col>
                <h1 className='address'>228/J, BANDARAGAMA ROAD, KESBAWA, SRI LANKA​</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                <iframe width="100%" height="500" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=500&amp;hl=en&amp;q=228/J,%20Bandaragama%20Road,%20Kesbewa,%20Sri%20Lanka%E2%80%8B+(Nithila%20Inn)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                    <a href="https://www.gps.ie/">Nithila inn</a></iframe>
                </Col>
            </Row>
        </Container>

        <div className="contact-card-image darken-with-text" style={{
            backgroundImage: `url(${contact_card})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '340px',
            position: 'relative',
          }}>
            <div className="contact-card-container">
              <h1 className="contact-card">NITHILA INN</h1>
              <h4 className="contact-card-text"> “The best way to find your self is to lose yourself in the service of others "</h4>
              <a href="https://twitter.com/" class="icon-button twitter"><i class="fab fa-twitter"></i><span></span></a>
              <a href="https://www.youtube.com/" class="icon-button facebook"><i class="fab fa-facebook"></i><span></span></a>
              <a href="https://web.facebook.com/NithilaFamilyRestaurant" class="icon-button youtube"><i class="fab fa-youtube"></i><span></span></a>
              <h1 className="contact-card">OUR HOTELS</h1>
              <h4 className="contact-card-text"> Kataragama – sella kataragama Rd, Kataragama | <a className='link' href='+94 77 732 5137'>+94 77 732 5137</a> | <a className='link' href='+94 777 758 489'>+94 777 758 489</a> |  <a className='link' href='https://nithilainn.com/'>nithilainn.com</a></h4>
            </div>
        </div>

        <Container>
          <Row>
            <Col>
                <ContactForm/>
            </Col>
          </Row>
        </Container>
        
        <HeaderFoot/>
        
    </div>
  )
}

export default ContactUs
