import React from 'react';
import Topbar from '../../Components/Topbar/Topbar';
import reception from '../../assets/06.jpg';
import './Reception.css';
import HeaderFoot from '../../Components/HeaderFoot/HeaderFoot';
import Accordion from '../../Components/Accordion/Accordion';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Reception = () => {

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const images = [
    '/assets/img/03-1.png',
    '/assets/img/01-1.png',
    '/assets/img/04.png',
    '/assets/img/05.png',
    '/assets/img/02-1.png',
  ];


  return (
    <div>
        <div className="bg-image darken-with-text" style={{
            backgroundImage: `url(${reception})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '780px',
            position: 'relative',
          }}>
            <Topbar/>
            <div className="reception-div">
              <h1 className='reception'>Reception</h1>
            </div>
        </div>


      <Accordion />
        <br/>

        <section className='img'> 
            <div className="carousel-container">
            <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="carousel-slide">
            <img src={image} alt={`Slide ${index}`} />
          </div>
        ))}
      </Slider>
            </div>
        </section>
        
      <HeaderFoot/>
    </div>
  )
}

export default Reception;
