import React from 'react';
import { useEffect } from 'react';
import { gsap } from 'gsap';
import './Accordion.css';
import { Col, Container, Row } from 'react-bootstrap';

function Accordion() {
    useEffect(() => {
        gsapAccordion();
      }, []);
      
      function gsapAccordion() {
        let groups = gsap.utils.toArray(".accordion-group");
        let menus = gsap.utils.toArray(".accordion-menu");
        let menuToggles = groups.map(createAnimation);
        
        menus.forEach((menu) => {
          menu.addEventListener("click", () => toggleMenu(menu));
        });
      
        function toggleMenu(clickedMenu) {
          menuToggles.forEach((toggleFn) => toggleFn(clickedMenu)); // Remove active class from child elements
          menus.filter(menu => menu !== clickedMenu).forEach(menu => { 
            menu.closest('.accordion-group').querySelector('.accordion-menu').classList.remove('active'); 
          }); 
          // Toggle the active class on clicked menu
          let parentMenu = clickedMenu.closest('.accordion-group').querySelector('.accordion-menu');
          parentMenu.classList.toggle('active'); 
        } 
      
        function createAnimation(element) { 
          let menu = element.querySelector(".accordion-menu"); 
          let box = element.querySelector(".accordion-content"); 
          let minusElement = element.querySelector(".accordion-minus"); 
          let plusElement = element.querySelector(".accordion-plus"); 
          
          gsap.set(box, { height: "auto" }); 
          let animation = gsap.timeline() 
            .from(box, { height: 0, duration: 0.5, ease: "power1.inOut" }) 
            .from(minusElement, { duration: 0.2, autoAlpha: 0, ease: "none" }, 0) 
            .to(plusElement, { duration: 0.2, autoAlpha: 0, ease: "none" }, 0) 
            .reverse(); 
          
          return function (clickedMenu) { 
            if (clickedMenu === menu) { 
              animation.reversed(!animation.reversed()); 
            } else { 
              animation.reverse(); 
            } 
          }; 
        } 
      } 
      
      gsapAccordion();
      
  return (
    <div>
          <Container>
          <Row>
          <Col class="parent-container1">
              <div class="accordion">
                  <div class="accordion-group">
                      <div class="accordion-menu">
                           Why should I choose Nithila Inn's Reception for check-in? 
                          <div class="accordion-plus">+</div>
                          <div class="accordion-minus">-</div>
                      </div>
                      <div class="accordion-content">
                          <div class="accordion-inner-content" /> 
                          <p>Nithila Inn’s Reception ensures a seamless check-in experience, combining efficiency with warmth. Your arrival is not just a process; 
                            it’s a personalized welcome to kickstart your enchanting stay. </p>
                          </div>
                  </div>
              </div>
            </Col>
            </Row>
            <Row>
          <Col class="parent-container2">
              <div class="accordion">
                  <div class="accordion-group">
                      <div class="accordion-menu">What makes Nithila Inn's receptionists unique?
                          <div class="accordion-plus">+</div>
                          <div class="accordion-minus">-</div>
                      </div>
                      <div class="accordion-content">
                          <div class="accordion-inner-content" /> 
                          <p>Our receptionists are more than check-in professionals; they are local experts ready to share insider tips, 
                            ensuring you uncover the best experiences the city has to offer during your stay. </p>
                          </div>
                  </div>
              </div>
            </Col>
            </Row>
            </Container>
        </div>
  )
}

export default Accordion