import './HeaderFoot.css';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';

const HeaderFoot = () => {
  const currentYear = new Date().getFullYear();
    return(
      <Navbar expand="lg" className="bg-transparent">
      <Container className="footer d-flex flex-column flex-md-row justify-content-between align-items-center">
        <Nav className="me-auto nav d-flex flex-column flex-md-row">
          <Nav.Link className="link" href="/">
            HOME
          </Nav.Link>
          <Nav.Link className="link" href="/Reception">
            RECEPTION
          </Nav.Link>
          <Nav.Link className="link" href="/Room">
            ROOM
          </Nav.Link>
          <Nav.Link className="link" href="/Restaurant">
            RESTAURANT
          </Nav.Link>
          <Nav.Link className="link" href="/ContactUs">
            CONTACT US
          </Nav.Link>
        </Nav>
        <div className="text-center text-md-left">
          <h1 className="footer-text">
            © {currentYear} Nithila Inn 
            {/* <a className="link-ceylon" href="https://ceylonxcorp.com/" rel="noreferrer" target="_blank">
              CeylonX
            </a> */}
          </h1>
        </div>
      </Container>
    </Navbar>
    )
    }
    
    export default HeaderFoot
