import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import logo from '../../assets/nithilainn-logo.png';
import { Button } from 'react-bootstrap';
import './Topbar.css';

const Topbar = () => {
    return (
<div>
      <Navbar expand="lg" className="bg-transparent navbar">
        <Container className="nav-container">
          <Navbar.Brand href="/">
            <img className="logo" src={logo} alt="logo" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="nav-toggle" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav className="nav-head gap-3 gap-md-5 align-items-center">
              <Nav.Link href="/" className="nav-link">
                HOME
              </Nav.Link>
              <Nav.Link href="/Reception" className="nav-link">
                RECEPTION
              </Nav.Link>
              <Nav.Link href="/Room" className="nav-link">
                ROOM
              </Nav.Link>
              <Nav.Link href="/Restaurant" className="nav-link">
                RESTAURANT
              </Nav.Link>
              <Nav.Link href="/ContactUs" className="nav-link">
                CONTACT US
              </Nav.Link>
              <Nav.Link href="https://nithilarest.com/" className="nav-link">
                <Button variant="success" className="btn">
                  Click here to visit Nithila Rest
                </Button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
    );
};

export default Topbar