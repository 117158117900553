import React, { useEffect } from 'react'
import './Restaurant.css';
import Topbar from '../../Components/Topbar/Topbar';
import HeaderFoot from '../../Components/HeaderFoot/HeaderFoot';
import restaurant from '../../assets/03.jpg';
import { Col, Container, Row } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import contact_card from '../../assets/13722099_5328478.jpg';
import Image from "react-bootstrap/Image";
import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';

import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from 'mdb-react-ui-kit';

const Restaurant = () => {
  const [isToggled, setIsToggled] = useState([false, false, false]);
  const [basicModal, setBasicModal] = useState(false);
  const [activeImageTab, setActiveImageTab] = useState(1);
  const [animateImage, setAnimateImage] = useState(false);

  const buttonStyle = (index) => ({
    backgroundColor: isToggled[index] ? 'Black' : 'White',
    color: isToggled[index] ? 'White' : 'Black',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  });

  const toggleOpen = () => setBasicModal(!basicModal);

  const handleChangeImage = (tab) => {
    setIsToggled((prevState) => prevState.map((_, i) => (i === tab - 1 ? !prevState[i] : false)));
    setAnimateImage(true);
    setActiveImageTab(tab);
    setTimeout(() => {
      setAnimateImage(false);
    }, 1000);
  };

    useEffect(()=>{
        AOS.init({
            
            disable: false, 
            startEvent: 'DOMContentLoaded', 
            initClassName: 'aos-init', 
            animatedClassName: 'aos-animate', 
            useClassNames: false, 
            disableMutationObserver: false, 
            debounceDelay: 50, 
            throttleDelay: 99,             
          
            
            offset: 120, 
            delay: 0, 
            duration: 400, 
            easing: 'ease', 
            once: false, 
            mirror: false, 
            anchorPlacement: 'top-bottom', 
          
          });
    })

  return (
    <div>
      <section>
      <div className="bg-image darken-with-text" style={{
            backgroundImage: `url(${restaurant})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '780px',
            position: 'relative',
          }}>
            <Topbar/>
            <div className="restaurant-container">
              <h1 className='restaurant'>OUR FAMILY RESTAURANT</h1>
            </div>
        </div>
        </section>



  <Container className="types-container">
    <Row className="btn-container justify-content-center">
      <Col md={4} sm={12}>
        <button
          style={buttonStyle(0)}
          onClick={() => handleChangeImage(1)}
          className="btn btn-primary"
        >
          ENTRANCE
        </button>
      </Col>
      <Col md={4} sm={12}>
        <button
          style={buttonStyle(1)}
          onClick={() => handleChangeImage(2)}
          className="btn btn-primary"
        >
          VIP ROOMS
        </button>
      </Col>
      <Col md={4} sm={12}>
        <button
          style={buttonStyle(2)}
          onClick={() => handleChangeImage(3)}
          className="btn btn-primary"
        >
          PUB
        </button>
      </Col>
    </Row>
    <Row>
      <Col md={4} className="h-100">
        <Image
          onClick={toggleOpen}
          src={
            activeImageTab === 1
              ? "/assets/img/ENT-01.png"
              : activeImageTab === 2
              ? "/assets/img/VIP-01.png"
              : "/assets/img/PUB-01.png"
          }
          fluid
          className={animateImage ? "animated-image h-100" : "h-100"}
        />
      </Col>
      <Col md={4} className="h-100">
        <Image
          onClick={toggleOpen}
          src={
            activeImageTab === 1
              ? "/assets/img/ENT-02.png"
              : activeImageTab === 2
              ? "/assets/img/VIP-02.png"
              : "/assets/img/PUB-02.png"
          }
          fluid
          className={animateImage ? "animated-image h-100" : "h-100"}
        />
      </Col>
      <Col md={4} className="h-100">
        <Image
          onClick={toggleOpen}
          src={
            activeImageTab === 1
              ? "/assets/img/ENT-03.png"
              : activeImageTab === 2
              ? "/assets/img/VIP-03.png"
              : "/assets/img/PUB-03.png"
          }
          fluid
          className={animateImage ? "animated-image h-100" : "h-100"}
        />
      </Col>
    </Row>
  </Container>

  <div className="Modal-carousel">
    <MDBModal className="MDmodal" open={basicModal} onClose={() => setBasicModal(false)} tabIndex="-1">
      <MDBModalDialog size="fullscreen">
        <MDBModalContent className="modal">
          <MDBModalBody>
            <MDBBtn className="btn-close" color="none" onClick={toggleOpen}></MDBBtn>
            <Carousel className="carousel d-flex justify-content-center">
              <Carousel.Item interval={500}>
                <img
                  src={
                    activeImageTab === 1
                      ? "/assets/img/ENT-01.png"
                      : activeImageTab === 2
                      ? "/assets/img/VIP-01.png"
                      : "/assets/img/PUB-01.png"
                  }
                  fluid
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item interval={500}>
                <img
                  src={
                    activeImageTab === 1
                      ? "/assets/img/ENT-02.png"
                      : activeImageTab === 2
                      ? "/assets/img/VIP-02.png"
                      : "/assets/img/PUB-02.png"
                  }
                  fluid
                  alt="Second slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={
                    activeImageTab === 1
                      ? "/assets/img/ENT-03.png"
                      : activeImageTab === 2
                      ? "/assets/img/VIP-03.png"
                      : "/assets/img/PUB-03.png"
                  }
                  fluid
                  alt="Third slide"
                />
              </Carousel.Item>
            </Carousel>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  </div>

        <div className="contact-card-image darken-with-text" style={{
            backgroundImage: `url(${contact_card})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '340px',
            position: 'relative',
          }}>
            <div className="contact-card-container">
              <h1 className='contact-card'>OPENING TIME</h1>
              <Container>
                <Row>
                  <Col><h4 className='day'>Monday</h4></Col>
                  <Col><h4 className='time'>10 A.M. - Midnight</h4></Col>
                </Row>
                <Row>
                  <Col><h4 h4 className='day'>Tuesday- Wednesday</h4></Col>
                  <Col><h4 className='time'>10 A.M. - Midnight</h4></Col>
                </Row>
                <Row>
                  <Col><h4 h4 className='day'>Thursday - Friday</h4></Col>
                  <Col><h4 className='time'>10 A.M. - Midnight</h4></Col>
                </Row>
                <Row>
                  <Col><h4 h4 className='day'>Saturday - Sunday</h4></Col>
                  <Col><h4 className='time'>10 A.M. - Midnight</h4></Col>
                </Row>
              </Container>
            </div>
        </div>

        <HeaderFoot/>
    </div>
  )
}

export default Restaurant
