import $ from 'jquery';
import 'jquery-validation';

$(document).ready(function() {
  $("#contact").validate({
    rules: {
      website: {
        required: true,
        url: true
      }
    },
    submitHandler: function(form) {
      form.submit();
    },
    messages: {
        name: 'Please enter your name',
        email: {
          required: 'Please enter your email',
          email: 'Please enter a valid email address'
        },
        message: 'Please enter a message'
      }
  });
});