import './Header.css';
import background from '../../assets/Home-scaled.jpg';
import Topbar from '../Topbar/Topbar';

const Header = () => {
    return(
      <div>
        <div className="home-bg-image darken-with-text" style={{
            backgroundImage: `url(${background})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '800px',
            position: 'relative',
          }}>
            <Topbar />
            <div className="content-container">
              <p className='welcome'>Welcome to</p>
              <h1 className='nithila'>Nithila inn</h1>
              <p className='tag'>"Where we specialize in elevating the ordinary to the extraordinary on every plate”</p>
            </div>
        </div>
      </div>
    )
    }
    
    export default Header