import './Home.css';
import Header from "../../Components/Header/Header";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import restaurant from '../../assets/Nithila-Inn-Family-Restaurant.png';
import accomadation from '../../assets/Nithila-Inn-Accomodation_01.jpg';
import bar from '../../assets/Nithila-Inn-Bar.png';
import reception from '../../assets/Nithila-Wedding-Reception.png';
import img1 from '../../assets/03.png'; 
import img2 from '../../assets/02.png';
import img3 from '../../assets/01.png';
import video from '../../assets/nithila.mp4'
import HeaderFoot from '../../Components/HeaderFoot/HeaderFoot';

const Home = () => {
return(
    <div>
        <Header/>
        <Container>
        <Row>
            <Col>
                <p className='sub-title'>"GOOD TIMES AND GREAT FOOD - THE PERFECT RECIPE FOR UNFORGETTABLE MOMENTS."</p>
                <h1 className='hh1'>Experience at Nithila inn</h1>
            </Col>
        </Row>
        </Container>

        <Container>
            <Row>
                <Col lg={3} xs={12}>
                  <img className='img' src={restaurant} alt='logo' style={{ width: "100%", height: "auto", objectFit: "cover" }} />
                  <h3 className='fw-bold'>Restaurant</h3>
                  <p>Indulge your palate in a culinary adventure. Our chefs prepare exquisite dishes using the freshest ingredients, ensuring a delightful dining experience.</p>
                </Col>
                <Col lg={3} xs={12}>
                  <img className='img' src={accomadation} alt='logo' style={{ width: "100%", height: "auto", objectFit: "cover" }} />
                  <h3 className='fw-bold'>Accomadation</h3>
                  <p>Your comfort is our priority. Relax in our meticulously designed rooms and suites equipped with modern amenities and luxurious touches.</p>
                </Col>
                <Col lg={3} xs={12}>
                  <img className='img' src={bar} alt='logo' style={{ width: "100%", height: "auto", objectFit: "cover" }} />
                  <h3 className='fw-bold'>Bar</h3>
                  <p>Elevate your experience at our lively bar. Enjoy expertly crafted drinks in a vibrant atmosphere perfect for unwinding and socializing.</p>
                </Col>
                <Col lg={3} xs={12}>
                  <img className='img' src={reception} alt='logo' style={{ width: "100%", height: "auto", objectFit: "cover" }} />
                  <h3 className='fw-bold'>Reception</h3>
                  <p>Seamless service starts here. Our welcoming staff ensures a smooth check-in and stands ready to assist throughout your stay.</p>
                </Col>
            </Row>
        </Container>

        <Container>
        <Row>
            <Col>
              <div className='video'>
               <video className='clip' src={video} width={1200} height={"auto"} controls />
            </div>
            </Col>
        </Row>
        </Container>

        <Container>
        <Row>
            <Col lg={4} xs={12}>
               <img className='img' src={img1} alt='logo' style={{ width: "100%", height: "auto", objectFit: "cover" }} />
            </Col>
            <Col lg={4} xs={12}>
               <img className='img' src={img2} alt='logo' style={{ width: "100%", height: "auto", objectFit: "cover" }} />
            </Col>
            <Col lg={4} xs={12}>
               <img className='img' src={img3} alt='logo' style={{ width: "100%", height: "auto", objectFit: "cover" }} />
            </Col>
        </Row>
        </Container>

        <HeaderFoot/>

    </div>
)
}

export default Home