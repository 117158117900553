import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home/Home';
import Reception from './pages/Reception/Reception';
import ContactUs from './pages/ContactUs/ContactUs';
import Restaurant from './pages/Restaurant/Restaurant';
import Room from './pages/Room/Room';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
            <Route path='/' element={<Home/>}></Route>
            <Route path='/Reception' element={<Reception/>}></Route>
            <Route path='/ContactUs' element={<ContactUs/>}></Route>
            <Route path='/Restaurant' element={<Restaurant/>}></Route>
            <Route path='/Room' element={<Room/>}></Route>
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
