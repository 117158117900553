import React from 'react';
import './ContactForm.css';
import './Validation';
import { useForm, ValidationError } from '@formspree/react';

function ContactForm() {
  const [state, handleSubmit] = useForm("xdoqrdlp");
  if (state.succeeded) {
      return <p>Thanks for joining!</p>;
  }
  return (
    <div className="container-form">
      <h3 className='contact-head fw-bold'>Contact Form</h3>
    <form onSubmit={handleSubmit} id='contact'  action="https://formspree.io/f/xdoqrdlp" method="POST">
      <input
        id="name"
        type="text" 
        name="name"
        placeholder='Enter Your Good Name Here'
      />
      <ValidationError 
        prefix="name" 
        field="name"
        errors={state.errors}
      />
      <input
        id="email"
        type="email" 
        name="email"
        placeholder='Your Email'
      />
      <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      />
      <input
        id="phone"
        type="text" 
        name="phone"
        placeholder='Your Mobile number'
      />
      <ValidationError 
        prefix="phone" 
        field="phone"
        errors={state.errors}
      />
      <textarea
        id="message"
        name="message"
        placeholder='Your message....'
      />
      <ValidationError 
        prefix="Message" 
        field="message"
        errors={state.errors}
      />
      <button type="submit" disabled={state.submitting}>
        Send Now
      </button>
    </form>
    </div>
  );
}

export default ContactForm