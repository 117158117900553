import React from 'react';
import './Room.css';
import room from '../../assets/01.jpg';
import Topbar from '../../Components/Topbar/Topbar';
import HeaderFoot from '../../Components/HeaderFoot/HeaderFoot';
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from 'react-bootstrap/Carousel';
import { useState } from 'react';

import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalBody,
  } from 'mdb-react-ui-kit';
  

function Room() {

      // Initialize state for button color
   const [isToggled, setIsToggled] = useState(false);
 
   // Determine button style based on state
   const buttonStyle1 = {
     backgroundColor: isToggled ? 'White' : 'Black',
     color: isToggled ? 'Black' : 'White',
     padding: '10px 20px',
     border: 'none',
     borderRadius: '5px',
     cursor: 'pointer'
   };

   const buttonStyle2 = {
    backgroundColor: isToggled ? 'Black' : 'White',
    color: isToggled ? 'White' : 'Black',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer'
  };

  const [basicModal, setBasicModal] = useState(false);

  const toggleOpen = () => setBasicModal(!basicModal);

  const [activeImageTab, setActiveImageTab] = useState(1);
  const [animateImage, setAnimateImage] = useState(false);

  const handleChangeImage = (tab) => {
    setIsToggled(prevState => !prevState);
    setAnimateImage(true);
    setActiveImageTab(tab);
    setTimeout(() => {
      setAnimateImage(false);
    }, 1000);
  };


  return (
    <div>
       <section className="bg-image darken-with-text" style={{
            backgroundImage: `url(${room})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '780px',
            position: 'relative',
          }}>

            <Topbar/>

            <div className="room-container">
              <h1 className='room'>COMFORT ROOMS</h1>
            </div>
       </section>

       <section className="types">
        <h1>Accommodation Types</h1>
        <section class="image-hover-section p-4">
            <div class="container text-center">
                <div class="row">
                  <div class="col-sm text-start">                
                    <Image src="../../assets/img/Room-3.jpg" fluid />
                    <h2>A/C</h2>
                    <p>Per Day</p>
                    <p>Rs. 5000.00</p>
                  </div>
                  <div class="col-sm text-start">
                    <Image src="../../assets/img/12.png" fluid />
                    <h2>NON A/C</h2>
                    <p>Per Day</p>
                    <p>Rs. 4000.00</p>      
                  </div>
                  <div class="col-sm text-start">
                    <Image src="/assets/img/10.png" fluid />
                    <h2>FULL HOUSE</h2>
                    <p>Per Day</p>
                    <p>A/C – Rs. 14000.00</p>
                    <p>Non A/C – Rs. 10000.00</p>       
                  </div>
                </div>
            </div>
        </section>
      </section>

          <Container className="types-container">
            <Row>
              <Col sm={12} className="btn-container">
                <button style={buttonStyle1} onClick={() => handleChangeImage(1)}>AC & NON A/C</button>
                <button style={buttonStyle2} onClick={() => handleChangeImage(2)}>FULL HOUSE</button>
              </Col>
              <Col md={4}>
                <Image onClick={toggleOpen}
                  src={
                    activeImageTab === 1
                      ? "/assets/img/Room-1.jpg"
                      : "/assets/img/Room-16-1.jpg"
                  }
                  fluid
                  className={animateImage ? "animated-image" : ""}
                />
              </Col>
              <Col md={4}>
                <Image onClick={toggleOpen}
                  src={
                    activeImageTab === 1
                      ? "/assets/img/Room-3.jpg"
                      : "/assets/img/Room-12.jpg"
                  }
                  fluid
                  className={animateImage ? "animated-image" : ""}
                />
              </Col>
              <Col md={4}>
                <Image onClick={toggleOpen}
                  src={
                    activeImageTab === 1
                      ? "/assets/img/Room-8.jpg"
                      : "/assets/img/Room-15.jpg"
                  }
                  fluid
                  className={animateImage ? "animated-image" : ""}
                />
              </Col>
              <Col md={4}>
                <Image onClick={toggleOpen}
                  src={
                    activeImageTab === 1
                      ? "/assets/img/5-1.jpg"
                      : "/assets/img/Room-14.jpg"
                  }
                  fluid
                  className={animateImage ? "animated-image" : ""}
                />
              </Col>
              <Col md={4}>
              <Image onClick={toggleOpen}
                  src={
                    activeImageTab === 1
                      ? "/assets/img/12.png"
                      : "/assets/img/Room-19.jpg"
                  }
                  fluid
                  className={animateImage ? "animated-image" : ""}
                />
              </Col>

  <div className="Modal-carousel">
              <MDBModal className="MDmodal"  open={basicModal} onClose={() => setBasicModal(false)} tabIndex='-1'>
                  <MDBModalDialog size='fullscreen' >
                    <MDBModalContent className="modal">
                      <MDBModalBody>
                      <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
                      <Carousel className="carousel">
      <Carousel.Item interval={500}>
      <img
                    src={
                      activeImageTab === 1
                        ? "/assets/img/Room-1.jpg"
                        : "/assets/img/Room-16-1.jpg"
                    }
                    fluid
                    alt="First slide"
                  />
      </Carousel.Item>
      <Carousel.Item interval={500}>
      <img
                    src={
                      activeImageTab === 1
                        ? "/assets/img/Room-3.jpg"
                        : "/assets/img/Room-12.jpg"
                    }
                    fluid
                    alt="Second slide"
                  />
      </Carousel.Item>
      <Carousel.Item>
      <img
                    src={
                      activeImageTab === 1
                        ? "/assets/img/Room-8.jpg"
                        : "/assets/img/Room-15.jpg"
                    }
                    fluid
                    alt="Third slide"
                  />
      </Carousel.Item>
      <Carousel.Item>
      <img
                    src={
                      activeImageTab === 1
                        ? "/assets/img/5-1.jpg"
                        : "/assets/img/Room-14.jpg"
                    }
                    fluid
                    alt="Third slide"
                  />
      </Carousel.Item>
      <Carousel.Item>
      <img
                    src={
                      activeImageTab === 1
                        ? "/assets/img/12.png"
                        : "/assets/img/Room-19.jpg"
                    }
                    fluid
                    alt="Third slide"
                  />
      </Carousel.Item>
    </Carousel>
    </MDBModalBody>

    </MDBModalContent>
    </MDBModalDialog>
    </MDBModal>
    </div>
    </Row>
    </Container>


    <HeaderFoot/>

    </div>
  )
}

export default Room